@use "sass:string";
@use "sass:color";
@use "sass:math";

@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import url('https://fonts.googleapis.com/css2?family=Piazzolla:ital,wght@0,500;0,800;1,500;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "@fortawesome/fontawesome-free/css/all.css";
@import "tippy.js/dist/tippy.css";


$input-padding: .58rem; // To make all inputs the same size as react-select
$spacing: 1.2rem;

:root {
  quotes: "« " " »";
  $color-1: #52A88C;
  $color-1-light: color.adjust($color-1, $lightness: +25%);
  $color-1-dark: color.adjust($color-1, $lightness: -15%);
  //$color-complementary: #a8526e;
  //$color-2: #adffe4;
  //$color-3: #82e0c1;
  //$color-4: #944a38;
  //$color-5: #e09582;
  --color-main: #{$color-1};
  --color-main-light: #{$color-1-light};
  --color-main-dark: #{$color-1-dark};
  --color-text: hsl(0 0% 30% / 1);
  --color-text-light: hsl(0 0% 60% / 1);
  --color-border: hsl(0 0% 80% / 1);
  --color-grey-background: hsl(0 0% 95% / 1);
  --color-dark-background: hsl(0 0% 20% / 1);
  --color-shadow: hsla(0, 0%, 0%, .2);
  --color-link: #1a0dab;
  --color-link-visited: #609;
  --color-homepage-concept: #{color.change($color-1, $hue: 202deg)};
  $color-panel-1: #ffd66f;
  $color-panel-2: #6FFF8E;
  $color-panel-3: #6F98FF;
  $color-panel-4: #FF6FE0;
  --color-panel-1: #{color.adjust($color-panel-1, $lightness: +15%)};
  --color-panel-2: #{color.adjust($color-panel-2, $lightness: +15%)};
  --color-panel-3: #{color.adjust($color-panel-3, $lightness: +15%)};
  --color-panel-4: #{color.adjust($color-panel-4, $lightness: +15%)};
}

html, body {
  height: 100%;
}

body {
  font-family: 'Piazzolla', serif;
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  background: url("./images/circles-light.webp");
}

body > * {
  flex-shrink: 0;
}

.header + .container {
  padding-top: 2 * $spacing;
  padding-bottom: 2 * $spacing;
}

.footer {
  background: var(--color-dark-background);
  color: white;
  padding: 5rem 0;
  margin-top: auto;
  a, a:visited {
    color: white;
  }
}

button {
  font: inherit;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

// A generic :focus affects quill editor and is quite ugly
input:focus,
textarea:focus {
  outline: 1px auto var(--color-main-light); // auto instead of solid makes the outline rounded corner. This works great on chrome but makes safari keep its default color
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-big {
  font-size: 1.33rem;
}

.text-small {
  font-size: .80rem;
}

.text-bold {
  font-weight: bold;
}

.text-highlight {
  color: var(--color-main);
}

.text-light {
  color: var(--color-text-light);
}

.text-italic {
  font-style: italic;
}

.visible-on-hover-container {
  .visible-on-hover {
    visibility: hidden;
  }

  &:hover .visible-on-hover {
    visibility: visible;

  }
}

.space {
  margin-top: $spacing;
  margin-bottom: $spacing;
}

.space-2 {
  margin-top: 2 * $spacing;
  margin-bottom: 2 * $spacing;
}

.space-4 {
  margin-top: 4 * $spacing;
  margin-bottom: 4 * $spacing;
}

h1, h2, h3, p, ul, ol, .space, .space-2, .space-4 {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.space-top {
  margin-top: $spacing !important;
}

.space-bottom {
  margin-bottom: $spacing !important;
}

.space-top-2 {
  margin-top: 2 * $spacing !important;
}

.space-bottom-2 {
  margin-bottom: 2 * $spacing !important;
}

.space-top-4 {
  margin-top: 4 * $spacing !important;
}

.space-bottom-4 {
  margin-bottom: 4 * $spacing !important;
}

.row.space-items {
  row-gap: math.div($spacing, 2);
}

.row.space-items-2 {
  row-gap: $spacing;
}

:link {
  color: var(--color-link);
}

:visited {
  color: var(--color-link-visited);
}

:link, :visited {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-link {
  color: var(--color-main);
  cursor: pointer;
  text-decoration: none;
}

@mixin btn($color-btn-background, $color-btn-border, $color-btn-shadow, $color-btn-text) {
  cursor: pointer;
  display: inline-block;
  background: $color-btn-background;
  border: 1px solid $color-btn-border;
  color: $color-btn-text;
  padding: .65em;
  border-radius: 3px;
  box-shadow: 3px 3px 1px 0 $color-btn-shadow;

  &, &:hover { // Override links underline
    text-decoration: none;
  }

  &:focus {
    outline: none;

    &::first-letter {
      text-decoration: underline;
    }
  }

  &:active {
    transform: translate(2px, 2px);
    box-shadow: 1px 1px 0 0 $color-btn-shadow;
  }
  &[disabled] {
    opacity: .5;
  }
}

.btn {
  @include btn(var(--color-main), var(--color-main), var(--color-main-dark), white);
}

.btn-2 {
  @include btn(var(--color-grey-background), var(--color-border), var(--color-border), black);
}

[disabled] {
  cursor: default;
  pointer-events: none; // buttons are unclickable by default but not <a>s
}

ul.unstyle, ol.unstyle {
  padding: 0;

  > li {
    list-style-type: none;
  }
}

a.unstyle {
  // Allow to use a link as a container
  display: block;
  color: inherit;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

label {
  color: var(--color-main-dark);
}

%inputs-layout {
  &::placeholder {
    color: var(--color-text-light);
  }

  border: 1px solid var(--color-border);
  border-radius: 4px; // react-select have this so let's make it homogenous
}

input:not([type]),
input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea {
  color: inherit;
  @extend %inputs-layout;
  display: block; // used for margin fusions
  padding: $input-padding;
  width: 100%; // display block is not sufficient for input text
}

select {
  color: inherit;
  @extend %inputs-layout;
  display: block;
  height: string.unquote("calc(2 * #{$input-padding} + 1.2em)");
}

input[type=submit], input[type=button], input[type=reset] {
  white-space: normal; // having line breaks for long text
}

input[type=radio], input[type=checkbox] {
  padding-top: $input-padding;
  padding-bottom: $input-padding;

  + label {
    padding-top: $input-padding;
    padding-bottom: $input-padding;
  }
}

.header {
  background: white;
  padding-top: $spacing;
  padding-bottom: math.div($spacing, 2);
  box-shadow: 0 0 5px var(--color-shadow);

  a.logo {
    text-decoration: none;
    font-family: 'Arimo', sans-serif;
    font-size: 1.1rem;
    line-height: 1.3em;

    .quote {
      font-size: 1.7em;
      color: #dedede;
    }

    .name {
      font-size: 1.2em;
      font-weight: bold;
      letter-spacing: .005em;
      color: var(--color-main);

      > span {
        color: var(--color-main-light);
        font-style: italic;
      }
    }
  }

  #user-menu {
    padding: math.div($spacing, 2);
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: var(--color-grey-background);
    }
  }

  #user-dropdown {
    z-index: 1;
    right: 0;
    background: white;
    box-shadow: 0 5px 10px var(--color-shadow);

    a {
      display: block;
      padding: math.div($spacing, 2) $spacing;
    }
  }

  #category-menu {
    .category-heading {
      color: var(--color-text-light);
      text-decoration: none;
      padding-top: math.div($spacing, 2);
      padding-bottom: math.div($spacing, 2);
    }

    position: relative;

    nav {
      padding: $spacing;
      column-width: 10rem;

      a {
        display: inline-block;
        padding-top: math.div($spacing, 4);
        padding-bottom: math.div($spacing, 4);
      }
    }
  }
}

.ql-header .ql-picker-item, .ql-header .ql-picker-label {
  &[data-value="2"]::before {
    content: "Titre" !important;
  }

  &[data-value="3"]::before {
    content: "Sous-titre" !important;
  }

  &[data-value="0"]::before {
    content: "Normal" !important;
  }
}

.ql-toolbar.ql-snow {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--color-grey-background);
}

.ql-editor {
  min-height: 50vh;
}

.quill-html-format, .ql-editor {
  font-family: "Times New Roman", serif;
  text-align: justify;
  // Reset normalize.css style on sub,sup to support copy paste to google docs
  sub, sup {
    position: revert;
    top: revert;
    bottom: revert;
    vertical-align: revert;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }
}

@mixin text-mark($bgCcolor) {
  background: $bgCcolor;
  $padding: .2rem;
  padding: $padding;
  margin-left: -$padding;
  margin-right: -$padding;
  border-radius: 5px;
}

.text-mark-main {
  @include text-mark(var(--color-main));
  color: white;
}

.text-mark-1 {
  @include text-mark(var(--color-panel-1));
}

.text-mark-2 {
  @include text-mark(var(--color-panel-2));
}

.text-mark-3 {
  @include text-mark(var(--color-panel-3));
}

.text-mark-4 {
  @include text-mark(var(--color-panel-4));
}

.text-content-box, .ql-editor {
  background: white;
  font-size: 1.2rem;
  padding: 2 * $spacing;
  box-shadow: 2px 2px 3px var(--color-shadow);
}

.resume-box {
  border-radius: 15px;
  padding: $spacing;
  background: white;
  box-shadow: 8px 8px 6px 0 #00000052;
}

.box-simple {
  background: white;
  padding: $spacing;
  box-shadow: 2px 2px 3px var(--color-shadow);
}

.contributor-thumbnail {
  .avatar {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    line-height: 2.5rem;
    text-align: center;
    background: var(--color-main-light);
    border-radius: 50%;
    font-size: 2rem;
  }
}

.homepage-concept {
  background: linear-gradient(90deg, var(--color-main) 0%, var(--color-homepage-concept) 50%, var(--color-main) 100%);
  padding: 4rem 0;

  h1 {
    color: white;
    font-size: 2.5rem;
    text-shadow: 2px -2px 0px var(--color-main);
  }

  ul {
    color: rgba(white, .5);
    height: 14rem;
  }
}